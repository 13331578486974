module.exports = {
    home: {
        title: 'Glückwunsch! Holen Sie sich Ihr kostenloses Amazon-Geschenk!'
    },
    landing: {
        warranty: '24 Monate Garantie'
    },
    redeem: {
        tip1: 'Bitte beachten Sie:\n' +
            '①Die gleiche Bestellung kann nur einmal eingelöst werden\n' +
            '②Jeder Code kann nur einmal für ihn / jede Person eingelöst werden',
        tip2: 'Lassen Sie uns dieses erstaunliche Geschenk öffnen.\n' +
            'Bitte geben Sie Ihren Gutscheincode ein.',
        code: 'Code einlösen: ',
        codeAlert: 'Bitte geben Sie den korrekten Gutscheincode ein.',
    },
    warranty: {
        name: 'Name:',
        nameAlert: 'Der Name darf 32 Zeichen nicht überschreiten.',
        email: 'Email:',
        emailAlert: 'Die E-Mail-Adresse darf 64 Zeichen nicht überschreiten.',
        emailAlert2: 'Bitte geben Sie eine gültige E-Mail-Adresse einBitte geben Sie eine gültige E-Mail-Adresse ein.',
        order: 'Amazon-Bestellnummer:',
        orderTip: 'Beispiel：249-1234567-1234567（17 Zeichen）',
        orderAlert: 'Bitte geben Sie Ihre korrekte Amazon-Bestellnummer ein.',
        market: 'Kauf von:',
        otherContactTip: 'Hinweis: Wir werden Sie per E-Mail kontaktieren, aber es besteht die Möglichkeit, dass die E-Mail zurückgewiesen wird oder im Spam landet. Bitte geben Sie mindestens einen weiteren Kontakt an, damit wir Ihr Amanzon-Geschenk sicher und sicher versenden können. (LINE/Twitter/andere)',
        line: 'LINIEN-ID：',
        lineTip: 'Beispiel: freef',
        facebook: 'Facebook ID：',
        facebookTip: 'Beispiel: freef',
        twitter: 'TWITTER-ID：',
        twitterTip: 'Beispiel：@freef',
        otherContact: 'Andere Kontaktmöglichkeiten:',
        otherContactAlert: 'Bitte geben Sie mindestens einen weiteren Kontakt an.',
        warningTip: 'Bitte beachten Sie: Wir schützen die Privatsphäre der Verbraucher streng und geben konsequent keine persönlichen Daten weiter. Und wird Sie in keiner Form kontaktieren, um Sie zu stören.'
    },
    finished: {
        title1: 'Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.\n\nSobald Ihre Bewertung auf der Amazon-Produktseite angezeigt wird, senden wir Ihnen einen Amazon-Geschenkcode. Es dauert etwa 2-7 Tage.\n\nBitte beachten Sie: Kunden, die ein Geschenk erhalten haben, sind nicht teilnahmeberechtigt. Bitte haben Sie Verständnis dafür, dass dies kein Geschenk für Kunden ist, die ein Geschenk erhalten haben.',
        title2: 'Vielen Dank für die sehr ausführlichen Antworten.\n\nSobald Ihre Bewertung auf der Amazon-Produktseite angezeigt wird, senden wir Ihnen einen Amazon-Geschenkcode. Es dauert ungefähr 2-7 Tage.\nIn dem unwahrscheinlichen Fall, dass Sie die E-Mail nicht erhalten, überprüfen Sie bitte, ob die von Ihnen eingegebene E-Mail-Adresse falsch ist oder in Ihrer Junk-Mail landet.\n\nWir freuen uns auf Ihre weitere Schirmherrschaft für unser Unternehmen.',
        dissatisfied: 'Vielen Dank, dass Sie sich die Zeit genommen haben, dieses Formular auszufüllen.\n' +
            'Wir werden Ihren Vorschlag intern vollständig prüfen und uns erneut mit Ihnen in Verbindung setzen.\n' +
            '\n' +
            'Für den unwahrscheinlichen Fall, dass Sie die E-Mail nicht erhalten, überprüfen Sie bitte, ob die von Ihnen eingegebene E-Mail-Adresse korrekt ist oder nicht.\n' +
            'Bitte überprüfen Sie, ob es in Ihrer Junk-Mail landet.\n' +
            '\n' +
            'Wir freuen uns auf Ihre weitere Schirmherrschaft für unser Unternehmen.'
    },
    trial: {
        title: 'Zu Ihrer Information führen wir gelegentlich kostenlose neue Testkampagnen durch, also wählen Sie bitte die kostenlosen Testprodukte, an denen Sie interessiert sind, aus der Liste unten aus. (Mehrfachauswahl)',
        trialAlert: 'Wählen Sie mindestens eine aus',
        submit: 'Ich interessiere mich für die oben genannten Produkte',
        cancel: 'I\'Ich interessiere mich nicht für alle.'
    },
    trialFinished: {
        title: 'Vielen Dank für die sehr ausführlichen Antworten.',
        tip: 'Wir werden Sie kontaktieren, nachdem die kostenlose Testkampagne, an der Sie interessiert sind, durchgeführt wurde.\nWir führen Sie separat durch den konkreten Prozess\n' +
            '\n' +
            'Für den unwahrscheinlichen Fall, dass Sie die E-Mail nicht erhalten, überprüfen Sie bitte, ob die von Ihnen eingegebene E-Mail-Adresse korrekt ist oder nicht.\nBitte überprüfen Sie, ob es in Ihrer Junk-Mail landet.\n\n' +
            'Wir freuen uns auf Ihre weitere Schirmherrschaft für unser Unternehmen.'
    },
    survey: {
        title: 'Sind Sie mit Ihrem Kauf zufrieden?',
        option1: 'Ja',
        option2: 'NEIN',
        dissatisfiedAlert: 'Bitte wählen Sie aus, ob Sie mit Ihrem Kauf zufrieden sind.',
        reportTip1: 'Wie zufrieden sind Sie insgesamt mit diesem Kauf? Wenn Sie Fragen, Kommentare oder Wünsche haben, können Sie uns gerne schreiben.',
        reportTip2: 'Indem wir ständig nach der Kundenzufriedenheit fragen, können wir uns über den Zustand des Produkts auf dem Laufenden halten, und durch die freie Eingabe von Meinungen und Anforderungen finden wir Punkte zur Verbesserung des Produkts.',
        reportAlert: 'Wenn Sie irgendwelche Probleme haben, zögern Sie bitte nicht, uns dies mitzuteilen.'
    },
    review: {
        title: 'Vielen Dank für Ihren Einkauf und Ihre Unterstützung',
        reviewTip1: 'Herzlichen Glückwunsch, es\'Es ist Zeit, Ihr Geschenk zu bekommen.' ,
        reviewTip2: 'Bitte befolgen Sie die nachstehenden Schritte, um eine Bewertung bei Amazon abzugeben.',
        reviewTip1_1: '①　Öffnen Sie Amazon und melden Sie sich an\n②　Klicken Sie auf [Ihr Konto]\n③　Klicken Sie im Dropdown-Feld von [Ihr Konto] auf [Ihre Bestellungen (Alle anzeigen)].\n④　Wählen Sie das Produkt aus, für das Sie eine Bewertung schreiben möchten\n⑤　Scrollen Sie nach unten, um [Wie ist Ihr Artikel? (Eine Produktbewertung schreiben)]\n⑥　Nachdem Sie die Seite aufgerufen haben, schreiben Sie Schritt für Schritt zuerst den Namen und geben Sie dann die Gesamtbewertung ein (5 Sterne erforderlich), fügen Sie Fotos/Videos hinzu, betiteln Sie Ihre Bewertung und fügen Sie eine Bewertung hinzu.',
        reviewTip1_2: 'Freuen Sie sich auf Ihre Kommentare und Fotos im Kommentarbereich! ',
        reviewWarning: 'bitte beachten Sie:',
        reviewWarning1: 'Bitte fügen Sie Ihrer Bewertung kein Foto des Geschenkgutscheins bei.',
        reviewWarning2: 'Bitte fügen Sie eine Bewertung des oben genannten Produkts bei, kein Foto des Produkts.',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Bitte fügen Sie Bewertungsdetails wie in den Beispielen 1 bis 3 oben bei.',
        imageAlert: 'Reichen Sie nicht nur Produktfotos ein. Wir benötigen einen Screenshot der Produktbewertung.',
        dialog: {
            title: 'Eine Notiz hinterlassen',
            tip1: 'Bitte überprüfen Sie {0} auf Screenshots, nicht auf Bilder des Produkts.',
            tip2: 'Inhalt der Produktbewertung',
            cancel: 'Nein, erneut einreichen',
            submit: 'Ja'
        }
    },
    alert: {
        90001: 'Diese Amazon-Bestellnummer wurde bereits verwendet, bitte geben Sie eine andere Amazon-Bestellnummer an.',
        90002: 'Bitte wählen Sie den richtigen Marktplatz aus.',
        90003: 'Diese Bestellnummer wurde bereits übermittelt. Bitte warten Sie noch ein paar Tage, während wir Ihre Bestellung überprüfen. Sobald Ihre Bewertung auf der Amazon-Produktseite angezeigt wird, senden wir Ihnen den Amazon-Geschenkcode innerhalb von 2-7 Tagen zu. Bitte haben Sie Geduld.',
        90004: 'Entschuldigung, nur eine Einlösung pro Person auf demselben Konto. Da Sie Ihren Amagif bereits eingelöst haben, ist das Einlösen für mehr als einen ungültig. Bitte verstehe.',
        90005: 'Vielen Dank für Ihre anhaltende Unterstützung. \nEs tut mir leid, aber Ihre Bestellnummer ist nicht für die Happy Scratch-Aktion berechtigt. (Scratch nicht teilnahmeberechtigt: diejenigen, die per Tweet am Wettbewerb teilnehmen / diejenigen, die After-Sales-Support benötigen). Bitte beachten Sie jedoch, dass wir dieser Anfrage in Zukunft nicht mehr nachkommen können. \nDanke für Ihr Verständnis.',
    },
    next: 'Nächste',
    previous: 'Vorherige',
    submit: 'Einreichen',
}