<template>
  <v-app :style="`background: ${color}`">
    <v-main>
      <v-row class="mt-4">
        <v-col align="center">
          <v-img class="rounded-circle" max-width="96" max-height="96"
                 :src="logo">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9" xl="6" class="mx-auto">
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component"/>
            </transition>
          </router-view>
        </v-col>
      </v-row>
      <v-row class="text--center mt-8 mb-16" justify="center">
        <v-tooltip v-for="contact in contacts" :key="contact.id" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon link class="mr-5" :href="contact.href" target="_blank"
                   v-bind="attrs"
                   v-on="on">
              <v-icon color="white" x-large>{{ contact.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ contact.id }}</span>
        </v-tooltip>
      </v-row>
<!--      <v-row justify="center">
        <p class="white&#45;&#45;text" style="font-size: 1px">{{color}}</p>
      </v-row>-->
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  computed: {
    logo() {
      return require('@/assets/logo.png')
    },
    color() {
      // return 'linear-gradient(' + this.randum(360) + 'deg, ' + this.randex() + ', ' + this.randex() + ')'
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },
    colors() {
      return [
          'linear-gradient(213deg, #3f4549, #8c94eb)',
          'linear-gradient(145deg, #406c41, #06b051)',
          'linear-gradient(71deg, #358fea, #1f1365)',
          'linear-gradient(36deg, #6437cf, #53639d)',
          'linear-gradient(277deg, #f1c20c, #2a73fc)',
          'linear-gradient(162deg, #205bb1, #9b94ba)',
          'linear-gradient(335deg, #f0f7d7, #bf4684)',
          'linear-gradient(239deg, #c4eaac, #483abc)',
          'linear-gradient(247deg, #0660d0, #a2abea)',
          'linear-gradient(44deg, #e2b7a9, #2d51a8)',
          'linear-gradient(100deg, #ae7ab9, #9b9f96)'
      ]
    }
  },
  data: () => ({
    contacts: [
      /*{
        id: '@SundayJP_',
        icon: 'fab fa-twitter',
        name: 'Sunday 【公式】',
        href: 'https://twitter.com/SundayJP_'
      },*/
      {
        id: 'huawenlinfeng@163.com',
        icon: 'fa-envelope',
        name: 'huawenlinfeng@163.com',
        href: 'mailto:huawenlinfeng@163.com'
      },
      /*{
        id: 'winbywin_jp',
        icon: 'fab fa-line',
        name: 'winbywin_jp',
        href: 'https://line.me/ti/p/J3p-MIXmdx'
      },*/
    ],
  }),
  methods: {
    randum(max) { //随机数
      return Math.round(Math.random() * max);
    },
    hexify(x) { //转换16进制
      return ('0' + parseInt(x).toString(16)).slice(-2);
    },
    randex() { //随机16进制色彩值
      return '#' + this.hexify(this.randum(255)) + this.hexify(this.randum(255)) + this.hexify(this.randum(255));
    },
    blender() { //随机渐变
      // if (Math.round(Math.random())) {
      //   return 'radial-gradient(circle at ' + this.randum(100) + '% ' + this.randum(100) + '%, ' + this.randex() + ', ' + this.randex() + ')';
      // } else {
      return 'linear-gradient(' + this.randum(360) + 'deg, ' + this.randex() + ', ' + this.randex() + ')'
      // }
    }
  },
  /*beforeMount() {
    var language = navigator.language.indexOf('-') > 0 ? navigator.language.split('-')[0] : navigator.language
    if (language === 'ja') {
      language = 'jp'
    }
    this.$i18n.locale = language.toUpperCase()

    if (['au', 'ca', 'de', 'es', 'fr', 'it', 'jp', 'uk', 'us'].includes(language.toLocaleLowerCase())) {
      this.$store.commit('review/MARKET', this.$i18n.locale)
    } else {
      console.log('不支持的语言项' + language + ', 默认设置为英语')
      this.$store.commit('review/MARKET', 'US')
    }
  }*/
};
</script>
