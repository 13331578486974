import Vue from "vue";
import Vuex from "vuex";
import createLogger from "@/plugins/logger";
// import access from "@/plugins/store/modules/access";
// import account from "@/plugins/store/modules/account";
import review from "@/plugins/store/modules/review";

Vue.use(Vuex);

const state = () => ({
    debug: process.env.NODE_ENV !== "production",
    contacts: [{
        name: "Email",
        text: "support@makeafriend.vip",
        icon: "mdi-email",
        href: "mailto:support@makeafriend.vip",
        color: "primary"
    }, {
        name: "Facebook",
        text: "Make a Friend",
        icon: "mdi-facebook",
        href: "fb://group/573817303776094",
        color: "blue"
    }, {
        name: "Whatsapp",
        text: "Make a Friend",
        icon: "mdi-whatsapp",
        href: "https://wa.me/8618300001159",
        color: "green"
    }],
});

const getters = {

};

const actions = {

};

const mutations = {

};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    modules: {
        // access,
        // account,
        review,
    },
    strict: state().debug,
    plugins: state().debug ? [createLogger()]: []
});