<template>
  <v-container fluid>
    <v-row v-for="language in languages" :key="language.lang">
      <v-col md="6" offset-md="3">
        <v-btn block x-large rounded @click="goto(language.lang)">
          <v-img class="float-left" max-width="46" max-height="46"
                 :src="language.image"/>
          <v-flex>
            {{ language.text }}
          </v-flex>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LandingPage',
  computed: {
    languages() {
      return [
        {
          lang: 'us',
          to: '/warranty/us',
          image: require('@/assets/icon/us.png'),
          text: 'Amazon US',
        },
        {lang: 'jp', to: '/warranty/jp', image: require('@/assets/icon/jp.png'), text: 'Amazon JP'},
        {lang: 'uk', to: '/warranty/uk', image: require('@/assets/icon/uk.png'), text: 'Amazon UK'},
        {lang: 'fr', to: '/warranty/fr', image: require('@/assets/icon/fr.png'), text: 'Amazon FR'},
        {lang: 'de', to: '/warranty/de', image: require('@/assets/icon/de.png'), text: 'Amazon DE'},
        {lang: 'es', to: '/warranty/es', image: require('@/assets/icon/es.png'), text: 'Amazon ES'},
        {lang: 'it', to: '/warranty/it', image: require('@/assets/icon/it.png'), text: 'Amazon IT'},
        {lang: 'ca', to: '/warranty/ca', image: require('@/assets/icon/ca.png'), text: 'Amazon CA'},
      ]
    },
  },
  data: () => ({}),
  methods: {
    goto(lang) {
      this.$i18n.locale = lang.toUpperCase()
      this.$store.commit('review/MARKET', this.$i18n.locale)
      this.$router.push('redeem')
    },
  },
}
</script>
