module.exports = {
    home: {
        title: 'Congratulazioni!  Ottieni il tuo regalo Amazon gratuito!'
    },
    landing: {
        warranty: '24 mesi di garanzia'
    },
    redeem: {
        tip1: 'notare che:\n' +
            '①Lo stesso ordine può essere riscattato solo una volta\n' +
            '②Ogni codice può essere riscattato solo una volta per lui/ogni persona',
        tip2: 'Permettere\'s apri questo fantastico regalo.\n' +
            'Inserisci il tuo codice di riscatto.',
        code: 'codice di riscatto: ',
        codeAlert: 'Inserisci il codice di riscatto corretto.',
    },
    warranty: {
        name: 'nome:',
        nameAlert: 'Il nome non deve superare i 32 caratteri.',
        email: 'Email:',
        emailAlert: 'L\'indirizzo e-mail non deve superare i 64 caratteri.',
        emailAlert2: 'Inserisci un indirizzo email validoInserisci un indirizzo email valido.',
        order: 'Numero d\'ordine Amazon:',
        orderTip: 'Example：249-1234567-1234567（17 caratteri）',
        orderAlert: 'Inserisci il numero d\'ordine Amazon corretto.',
        market: 'Acquista da:',
        otherContactTip: 'Nota: Ti contatteremo via e-mail, ma c\'è la possibilità che l\'e-mail venga rifiutata o finisca nello spam. Si prega di includere almeno un altro contatto in modo che possiamo inviare il tuo regalo Amazon in modo sicuro e protetto. (LINE/Twitter/altri)',
        line: 'LINE ID：',
        lineTip: 'Example：freef',
        facebook: 'Facebook ID：',
        facebookTip: 'Example：freef',
        twitter: 'TWITTER ID：',
        twitterTip: 'Example：@freef',
        otherContact: 'Altri modi per contattare:',
        otherContactAlert: 'Si prega di fornire almeno un altro contatto.',
        warningTip: 'Nota: proteggiamo rigorosamente la privacy personale dei consumatori e risolutamente non divulghiamo alcuna informazione personale. E non ti contatterà in nessuna forma per disturbarti.'
    },
    finished: {
        title1: 'Grazie mille per aver dedicato del tempo a compilare questo modulo.\n\nUna volta che la tua recensione si riflette sulla pagina del prodotto Amazon, ti invieremo un codice regalo Amazon. Ci vogliono circa 2-7 giorni.\n\nNota: i clienti che hanno ricevuto un regalo non sono idonei. Ti preghiamo di comprendere che questo non è un regalo per i clienti che hanno ricevuto un regalo.',
        title2: 'Grazie mille per le risposte molto dettagliate.\n\nUna volta che la tua recensione si riflette sulla pagina del prodotto Amazon, ti invieremo un codice regalo Amazon. Ci vogliono circa 2-7 giorni.\nNell\'improbabile eventualità che tu non riceva l\'e-mail, controlla se l\'indirizzo e-mail che hai inserito non è corretto o se finisce nella tua posta indesiderata.\n\nAttendiamo con impazienza il vostro continuo patrocinio della nostra azienda.',
        dissatisfied: 'Grazie mille per aver dedicato del tempo a compilare questo modulo.\n' +
            'Esamineremo internamente la tua proposta e ti ricontatteremo.\n' +
            '\n' +
            'Nel caso improbabile che tu non riceva l\'e-mail, controlla se l\'indirizzo e-mail che hai inserito è corretto o meno.\n' +
            'Controlla se finisce nella tua posta indesiderata.\n' +
            '\n' +
            'Attendiamo con impazienza il vostro continuo patrocinio della nostra azienda.'
    },
    trial: {
        title: 'Per tua informazione, occasionalmente organizziamo nuove campagne di prova gratuite, quindi seleziona i prodotti di prova gratuiti che ti interessano dall\'elenco sottostante. (Scelta multipla)',
        trialAlert: 'Scegline almeno uno',
        submit: 'Sono interessato ai prodotti di cui sopra',
        cancel: 'IO\'Non sono interessato a tutti loro.'
    },
    trialFinished: {
        title: 'Grazie mille per le risposte molto dettagliate.',
        tip: 'Ti contatteremo dopo la campagna di prova gratuita a cui sei interessato.\nTi guideremo attraverso il processo specifico separatamente\n' +
            '\n' +
            'Nel caso improbabile che tu non riceva l\'e-mail, controlla se l\'indirizzo e-mail che hai inserito è corretto o meno.\nControlla se finisce nella tua posta indesiderata.\n\n' +
            'Attendiamo con impazienza il vostro continuo patrocinio della nostra azienda.'
    },
    survey: {
        title: 'Sei soddisfatto del tuo acquisto?',
        option1: 'SÌ',
        option2: 'No',
        dissatisfiedAlert: 'Seleziona se sei soddisfatto del tuo acquisto.',
        reportTip1: 'Complessivamente, quanto sei soddisfatto di questo acquisto? Se hai domande, commenti o richieste, non esitare a scriverci.',
        reportTip2: 'Chiedendo costantemente informazioni sulla soddisfazione del cliente, possiamo tenerci aggiornati sulla salute del prodotto e inserendo liberamente opinioni e requisiti, troveremo punti per migliorare il prodotto.',
        reportAlert: 'In caso di problemi, non esitare a farcelo sapere.'
    },
    review: {
        title: 'Grazie per l\'acquisto e il supporto',
        reviewTip1: 'Congratulazioni\'s tempo per ricevere il tuo regalo.' ,
        reviewTip2: 'Segui i passaggi seguenti per inviare una recensione su Amazon.',
        reviewTip1_1: '①　Apri Amazon e accedi\n②　Clicca su [Il tuo account]\n③　Fai clic su [I tuoi ordini (vedi tutto) nella casella a discesa di [Il tuo account]\n④　Seleziona il prodotto per il quale desideri scrivere una recensione\n⑤　Scorri verso il basso per trovare [Come va il tuo oggetto? (Scrivere la recensione di un prodotto)]\n⑥　Dopo essere entrato nella pagina, passo dopo passo, scrivi prima il nome, quindi inserisci la valutazione complessiva (richieste 5 stelle), allegare foto/video, intitolare la recensione e aggiungere recensione.',
        reviewTip1_2: 'Non vedo l\'ora di vedere i tuoi commenti e le foto nella sezione commenti! ',
        reviewWarning: 'notare che:',
        reviewWarning1: 'Si prega di non includere una foto della carta regalo nella recensione.',
        reviewWarning2: 'Si prega di allegare una recensione del prodotto di cui sopra, non una foto del prodotto.',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Allega i dettagli della recensione come negli esempi 1~3 sopra.',
        imageAlert: 'Non inviare solo foto del prodotto. Abbiamo bisogno di uno screenshot della recensione del prodotto.',
        dialog: {
            title: 'Lascia una nota',
            tip1: 'Controlla {0} per gli screenshot, non le immagini del prodotto.',
            tip2: 'Contenuto della recensione del prodotto',
            cancel: 'No, invia di nuovo',
            submit: 'SÌ'
        }
    },
    alert: {
        90001: 'Questo numero d\'ordine Amazon è già stato utilizzato, fornisci un altro numero d\'ordine Amazon.',
        90002: 'Si prega di scegliere il mercato corretto.',
        90003: 'Questo numero d\'ordine è già stato inviato. Attendi ancora qualche giorno mentre esaminiamo il tuo ordine. Una volta che la tua recensione si riflette sulla pagina del prodotto Amazon, ti invieremo il codice regalo Amazon entro 2-7 giorni. Per favore sii paziente.',
        90004: 'Siamo spiacenti, solo un riscatto per persona sullo stesso account. Dal momento che hai già riscattato il tuo Amagif, il riscatto per più di uno non sarà valido. Per cortesia comprendi.',
        90005: 'Grazie per il vostro continuo supporto. \nMi scuso, ma il tuo numero d\'ordine non è idoneo per la promozione Happy Scratch. (Scratch non idonei: coloro che partecipano al concorso tramite tweet / coloro che richiedono assistenza post-vendita). Tuttavia, tieni presente che non saremo in grado di soddisfare nuovamente questa richiesta in futuro. \nGrazie per la vostra comprensione.',
    },
    next: 'Prossimo',
    previous: 'Precedente',
    submit: 'Invia',
}