<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col>
          <p class="text-h5">{{ $t('trialFinished.title') }}</p>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row class="mt-5">
        <v-col>
          <p class="text-subtitle-1 font-weight-bold text-pre-wrap">{{ $t('trialFinished.tip') }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TrialFinishedPage",
  computed: mapState({
    customer: state => state.review.customer,
  }),
  mounted() {
    // if (!this.customer) {
    //   this.$router.replace("/")
    // }
  },
}
</script>

<style scoped>

</style>