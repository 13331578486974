<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-container fluid>
        <v-row class="mt-3 text-center">
          <v-col>
            <span class="text-h5 font-weight-bold">{{ $t('review.title') }}</span>
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-5"/>
        <v-row>
          <v-col>
            <p class="font-weight-bold">{{ $t('review.reviewTip1') }}</p>
            <p class="font-weight-bold"><v-icon color="red" class="mr-1">fas fa-exclamation-triangle</v-icon>{{ $t('review.reviewTip2') }}</p>
            <p class="text-pre-wrap" v-html="$t('review.reviewTip1_1')"></p>
            <br/>
            <p class="text-pre-wrap font-weight-bold">{{ $t('review.reviewTip1_2') }}</p>
            <p class="blue--text text--darken-4">
              {{ $t('review.reviewWarning') }}
            </p>
            <p class="blue--text text--darken-4">
              <v-icon color="primary darken-4" class="mr-1">fas fa-exclamation-triangle</v-icon>
              {{ $t('review.reviewWarning1') }}
            </p>
            <p class="blue--text text--darken-4">
              <v-icon color="primary darken-4" class="mr-1">fas fa-exclamation-triangle</v-icon>
              {{ $t('review.reviewWarning2') }}
            </p>
<!--            <p class="blue&#45;&#45;text text&#45;&#45;darken-4">
              <v-icon color="primary darken-4" class="mr-1">fas fa-exclamation-triangle</v-icon>
              {{ $t('review.reviewTip3') }}
            </p>
            <p class="blue&#45;&#45;text text&#45;&#45;darken-4">
              <v-icon color="primary darken-4" class="mr-1">fas fa-exclamation-triangle</v-icon>
              {{ $t('review.reviewTip4') }}
            </p>-->
          </v-col>
        </v-row>
        <v-row>
<!--          <v-col offset="1" cols="10" sm="5" offset-sm="1">-->
          <v-col>
            <v-img :src="realImageUrl" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input show-size accept="image/png,image/jpg,image/jpeg"
                          :label="$t('review.imageTip')" v-model="image"
                          :rules="rules.image" :disabled="loading"/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="previous" large color="warning" class="mb-3 mr-3">{{
            $t('previous')
          }}
        </v-btn>
        <v-btn :loading="loading" @click="openDialog" large color="primary" class="mb-3">{{
            $t('submit')
          }}
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="unset" persistent>
      <v-card max-width="100vh">
        <v-card-title class="text-h5">
          {{ $t("review.dialog.title") }}
        </v-card-title>
        <v-card-text>
          <i18n path="review.dialog.tip1" for="review.dialog.tip2">
            <span class="red--text font-weight-bold">{{ $t("review.dialog.tip2") }}</span>
          </i18n>
          <v-img :src="getImageUrl" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="warning darken-1" text @click="dialog = false" :loading="loading">
            {{ $t("review.dialog.cancel") }}
          </v-btn>
          <v-btn color="primary darken-1" text @click="submit" :loading="loading">
            {{ $t("review.dialog.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ReviewPage",
  computed: {
    image: {
      get() {
        return this.$store.state.review.image
      },
      set(val) {
        this.$store.commit('review/IMAGE', val)
      }
    },
    realImageUrl() {
      if (this.image) {
        return URL.createObjectURL(this.image);
      } else if (this.market === "JP") {
        // return "https://s3-us-west-1.amazonaws.com/item.review/screenshot/example-jp.png";
        return this.getImageUrl
      } else {
        return "https://s3-us-west-1.amazonaws.com/item.review/screenshot/example.jpg";
      }
    },
    getImageUrl() {
      return this.$vuetify.breakpoint.mdAndUp ? require("@/assets/review_example2.jpeg") : require("@/assets/review_example1.jpeg")
    },
    ...mapState({
      customer: state => state.review.customer,
      market: state => state.review.marketId
    })
  },
  data() {
    return {
      loading: false,
      dialog: false,
      rules: {
        image: [
          v => !!v || this.$t("review.imageAlert")
        ],
      }
    }
  },
  methods: {
    previous() {
      this.$router.replace('/survey')
    },
    openDialog() {
      if (this.$refs.form.validate()) {
        this.dialog = true
      }
    },
    submit() {
      this.loading = true
      this.dialog = false
      this.submitReview().then(() => {
        this.loading = false
        this.$router.replace("/finished")
        // this.$router.replace("/trial/finished")
      }, () => {
        this.loading = false
      })
    },
    ...mapActions({
      submitReview: 'review/submit'
    })
  },
  mounted() {
    if (!this.customer) {
      this.$router.replace("/")
    }
  }
}
</script>

<style scoped>

</style>