module.exports = {
    home: {
        title: 'Toutes nos félicitations! Obtenez votre cadeau Amazon gratuit!'
    },
    landing: {
        warranty: 'Garantie 24 mois'
    },
    redeem: {
        tip1: 'Veuillez noter:\n' +
            '①La même commande ne peut être échangée qu\'une seule fois\n' +
            '②Chaque code ne peut être échangé qu\'une seule fois pour lui / chaque personne',
        tip2: 'Laisser\'ouvre ce cadeau incroyable.\n' +
            'Veuillez entrer votre code de remboursement.',
        code: 'utiliser le code: ',
        codeAlert: 'Veuillez entrer le bon code d\'échange.',
    },
    warranty: {
        name: 'name:',
        nameAlert: 'Le nom ne doit pas dépasser 32 caractères.',
        email: 'Email:',
        emailAlert: 'L\'adresse e-mail ne doit pas dépasser 64 caractères.',
        emailAlert2: 'Veuillez saisir une adresse e-mail valideVeuillez saisir une adresse e-mail valide.',
        order: 'Numéro de commande Amazon:',
        orderTip: 'Example：249-1234567-1234567（17 personnages）',
        orderAlert: 'Veuillez entrer votre numéro de commande Amazon correct.',
        market: 'Achat de:',
        otherContactTip: 'Remarque : Nous vous contacterons par e-mail, mais il est possible que l\'e-mail soit rejeté ou qu\'il se retrouve dans les spams.. Veuillez inclure au moins un autre contact afin que nous puissions envoyer votre cadeau Amazon en toute sécurité. (LIGNE/Twitter/autres)',
        line: 'LINE ID：',
        lineTip: 'Example：freef',
        facebook: 'Facebook ID：',
        facebookTip: 'Example：freef',
        twitter: 'TWITTER ID：',
        twitterTip: 'Example：@freef',
        otherContact: 'Autres moyens de contact:',
        otherContactAlert: 'Veuillez fournir au moins un autre contact.',
        warningTip: 'Veuillez noter : Nous protégeons strictement la vie privée des consommateurs et ne divulguons résolument aucune information personnelle. Et ne vous contactera sous aucune forme pour vous déranger.'
    },
    finished: {
        title1: 'Merci beaucoup d\'avoir pris le temps de remplir ce formulaire.\n\nUne fois votre avis reflété sur la page du produit Amazon, nous vous enverrons un code cadeau Amazon. Cela prend environ 2-7 jours.\n\nRemarque : les clients qui ont reçu un cadeau ne sont pas éligibles. Veuillez comprendre qu\'il ne s\'agit pas d\'un cadeau pour les clients qui ont reçu un cadeau.',
        title2: 'merci beaucoup pour vos réponses très détaillées.\n\nUne fois que votre avis est reflété sur la page du produit Amazon, nous vous enverrons un code cadeau Amazon. Cela prend environ 2-7 jours.\nDans le cas peu probable où vous ne recevriez pas l\'e-mail, veuillez vérifier si l\'adresse e-mail que vous avez saisie est incorrecte ou si elle va dans vos courriers indésirables.\n\nNous nous réjouissons de votre parrainage continu de notre entreprise.',
        dissatisfied: 'Merci beaucoup d\'avoir pris le temps de remplir ce formulaire.\n' +
            'Nous examinerons entièrement votre proposition en interne et vous recontacterons.\n' +
            '\n' +
            'Dans le cas peu probable où vous ne recevriez pas l\'e-mail, veuillez vérifier si l\'adresse e-mail que vous avez saisie est correcte ou non..\n' +
            'Veuillez vérifier s\'il se retrouve dans votre courrier indésirable.\n' +
            '\n' +
            'Nous nous réjouissons de votre parrainage continu de notre entreprise.'
    },
    trial: {
        title: 'Pour votre information, nous organisons occasionnellement de nouvelles campagnes d\'essai gratuites, veuillez donc sélectionner le ou les produits d\'essai gratuits qui vous intéressent dans la liste ci-dessous. (Choix multiple)',
        trialAlert: 'Choisissez au moins un',
        submit: 'Je suis intéressé par les produits ci-dessus',
        cancel: 'je\'ne s\'intéresse pas à tous.'
    },
    trialFinished: {
        title: 'Merci beaucoup pour vos réponses très détaillées.',
        tip: 'Nous vous contacterons après la campagne d\'essai gratuite qui vous intéresse.\nNous vous guiderons à travers le processus spécifique séparément\n' +
            '\n' +
            'Dans le cas peu probable où vous ne recevriez pas l\'e-mail, veuillez vérifier si l\'adresse e-mail que vous avez saisie est correcte ou non.\nVeuillez vérifier s\'il se retrouve dans votre courrier indésirable.\n\n' +
            'Nous nous réjouissons de votre parrainage continu de notre entreprise.'
    },
    survey: {
        title: 'Êtes-vous satisfait de votre achat?',
        option1: 'oui',
        option2: 'Non',
        dissatisfiedAlert: 'Veuillez sélectionner si vous êtes satisfait de votre achat.',
        reportTip1: 'Dans l\'ensemble, êtes-vous satisfait de cet achat ? Si vous avez des questions, des commentaires ou des demandes, n\'hésitez pas à nous écrire.',
        reportTip2: 'En nous interrogeant constamment sur la satisfaction du client, nous pouvons nous tenir au courant de la santé du produit, et en entrant librement des avis et des exigences, nous trouverons des points pour améliorer le produit.',
        reportAlert: 'Si vous rencontrez des problèmes, n\'hésitez pas à nous en faire part.'
    },
    review: {
        title: 'Merci pour votre achat et votre soutien',
        reviewTip1: 'Félicitations, il\'sil est temps de recevoir votre cadeauv.' ,
        reviewTip2: 'Veuillez suivre les étapes ci-dessous pour soumettre un avis sur Amazon.',
        reviewTip1_1: '①　Ouvrez Amazon et connectez-vous\n②　Cliquez sur [Votre compte]\n③　Cliquez sur [Vos commandes (Voir tout) dans la liste déroulante de [Votre compte]\n④　Sélectionnez le produit pour lequel vous souhaitez rédiger un avis\n⑤　Faites défiler vers le bas pour trouver [Comment va votre article ? (Écrire une revue de produit)]\n⑥　Après être entré dans la page, étape par étape, écrivez d\'abord le nom, puis entrez la note globale (5 étoiles requises), joignez des photos/vidéos, donnez un titre à votre avis et ajoutez un avis.',
        reviewTip1_2: 'Hâte de voir vos commentaires et photos dans la section des commentaires! ',
        reviewWarning: 'veuillez noter:',
        reviewWarning1: 'Veuillez ne pas inclure de photo de la carte-cadeau dans votre commentaire.',
        reviewWarning2: 'Veuillez joindre une critique du produit ci-dessus, pas une photo du produit.',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Veuillez joindre les détails de l\'examen comme dans les exemples 1 à 3 ci-dessus.',
        imageAlert: 'Ne soumettez pas uniquement des photos de produits. Nous avons besoin d\'une capture d\'écran de l\'examen du produit.',
        dialog: {
            title: 'Laisser une note',
            tip1: 'Veuillez vérifier {0} pour les captures d\'écran, pas les images du produit.',
            tip2: 'Contenu de l\'avis sur le produit',
            cancel: 'Non, soumettre à nouveau',
            submit: 'Oui'
        }
    },
    alert: {
        90001: 'Ce numéro de commande Amazon a déjà été utilisé, veuillez fournir un autre numéro de commande Amazon.',
        90002: 'Veuillez choisir le bon marché.',
        90003: 'Ce numéro de commande a déjà été soumis. Veuillez patienter encore quelques jours pendant que nous examinons votre commande. Une fois votre avis reflété sur la page du produit Amazon, nous vous enverrons le code cadeau Amazon dans un délai de 2 à 7 jours. S\'il vous plaît soyez patient.',
        90004: 'Désolé, un seul échange par personne sur le même compte. Puisque vous avez déjà échangé votre Amagif, l\'échange de plus d\'un sera invalide. Essaye de comprendre.',
        90005: 'Merci pour votre soutien continu. \nJe m\'excuse, mais votre numéro de commande n\'est pas éligible à la promotion Happy Scratch. (Scratch inadmissible : ceux qui participent au concours via tweet / ceux qui ont besoin d\'un service après-vente). Cependant, veuillez noter que nous ne pourrons plus répondre à cette demande à l\'avenir.. \nMerci pour votre compréhension.',
    },
    next: 'Suivant',
    previous: 'Précédent',
    submit: 'Soumettre',
}