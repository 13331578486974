module.exports = {
    home: {
        title: '¡Felicidades! Obtenga su regalo de Amazon gratis!'
    },
    landing: {
        warranty: '24 meses de garantía'
    },
    redeem: {
        tip1: 'tenga en cuenta:\n' +
            '①Un mismo pedido solo se puede canjear una vez\n' +
            '②Cada código solo se puede canjear una vez para él/cada persona',
        tip2: 'Dejar\'s abre este increíble regalo.\n' +
            'Por favor ingrese su código de canje.',
        code: 'Canjear código: ',
        codeAlert: 'Ingrese el código de canje correcto.',
    },
    warranty: {
        name: 'nombre:',
        nameAlert: 'El nombre no debe exceder los 32 caracteres.',
        email: 'Correo electrónico:',
        emailAlert: 'La dirección de correo electrónico no debe exceder los 64 caracteres.',
        emailAlert2: 'Ingrese una dirección de correo electrónico válidaIngrese una dirección de correo electrónico válida.',
        order: 'Número de pedido de Amazon:',
        orderTip: 'Ejemplo：249-1234567-1234567（17 caracteres）',
        orderAlert: 'Ingrese su número de pedido de Amazon correcto.',
        market: 'Comprar desde:',
        otherContactTip: 'Nota: Nos pondremos en contacto con usted por correo electrónico., pero existe la posibilidad de que el correo electrónico sea rechazado o termine en spam. Incluya al menos un contacto más para que podamos enviar su regalo de Amazon de forma segura. (LÍNEA/Twitter/otros)',
        line: 'LINE ID：',
        lineTip: 'Example：freef',
        facebook: 'Facebook ID：',
        facebookTip: 'Ejemplo：gratis',
        twitter: 'TWITTER ID：',
        twitterTip: 'Example：@freef',
        otherContact: 'Otras formas de contacto:',
        otherContactAlert: 'Proporcione al menos un contacto más.',
        warningTip: 'Tenga en cuenta: protegemos estrictamente la privacidad personal de los consumidores y no divulgamos ninguna información personal. Y no se comunicará con usted de ninguna forma para molestarlo..'
    },
    finished: {
        title1: 'Muchas gracias por tomarse el tiempo para llenar este formulario.\n\nUna vez que su opinión se refleje en la página del producto de Amazon, le enviaremos un código de regalo de Amazon. Tarda entre 2 y 7 días.\n\nTenga en cuenta: los clientes que recibieron un regalo no son elegibles. Por favor, comprenda que este no es un regalo para los clientes que recibieron un regalo..',
        title2: 'Muchas gracias por sus respuestas tan detalladas..\n\nUna vez que su reseña se refleje en la página del producto de Amazon, le enviaremos un código de regalo de Amazon. Tarda entre 2 y 7 días.\nEn el improbable caso de que no reciba el correo electrónico, compruebe si la dirección de correo electrónico que ingresó es incorrecta o si va a su correo no deseado.\n\nEsperamos su continuo patrocinio de nuestra empresa..',
        dissatisfied: 'Muchas gracias por tomarse el tiempo para llenar este formulario.\n' +
            'Revisaremos completamente su propuesta internamente y nos pondremos en contacto con usted nuevamente..\n' +
            '\n' +
            'En el improbable caso de que no reciba el correo electrónico, verifique si la dirección de correo electrónico que ingresó es correcta o no.\n' +
            'Por favor, compruebe si va a su correo no deseado.\n' +
            '\n' +
            'Esperamos su continuo patrocinio de nuestra empresa.'
    },
    trial: {
        title: 'Para su información, ocasionalmente realizamos nuevas campañas de prueba gratuitas, así que seleccione los productos de prueba gratuita que le interesen de la lista a continuación. (Opción multiple)',
        trialAlert: 'Elige al menos uno',
        submit: 'Estoy interesado en los productos anteriores.',
        cancel: 'I\'No interesado en todos ellos.'
    },
    trialFinished: {
        title: 'Muchas gracias por sus respuestas tan detalladas..',
        tip: 'Nos pondremos en contacto con usted después de la campaña de prueba gratuita en la que está interesado..\nLo guiaremos a través del proceso específico por separado.\n' +
            '\n' +
            'En el improbable caso de que no reciba el correo electrónico, verifique si la dirección de correo electrónico que ingresó es correcta o no.\nPor favor, compruebe si va a su correo no deseado..\n\n' +
            'We look forward to your continued patronage of our company.'
    },
    survey: {
        title: 'Are you satisfied with your purchase?',
        option1: 'Yes',
        option2: 'No',
        dissatisfiedAlert: 'Please select whether you are satisfied with your purchase.',
        reportTip1: 'En general, ¿cuál es su grado de satisfacción con esta compra? Si tienes alguna pregunta, comentarios o solicitudes, no dude en escribirnos.',
        reportTip2: 'Al preguntar constantemente sobre la satisfacción del cliente, podemos estar al tanto de la salud del producto, y al ingresar libremente opiniones y requisitos, encontraremos puntos para mejorar el producto.',
        reportAlert: 'Si tiene algún problema, no dude en hacérnoslo saber.'
    },
    review: {
        title: 'Gracias por su compra y apoyo',
        reviewTip1: 'Felicitaciones, es\'es hora de conseguir tu regalo.' ,
        reviewTip2: 'Siga los pasos a continuación para enviar una reseña en Amazon.',
        reviewTip1_1: '①　Abre Amazon e inicia sesión\n②　Haga clic en [Su cuenta]\n③　Haga clic en [Sus pedidos (Ver todos) en el cuadro desplegable de [Su cuenta]\n④　Seleccione el producto para el que desea escribir una reseña\n⑤　Desplácese hacia abajo para encontrar [¿Cómo está tu artículo? (Escribir la reseña de un producto)]\n⑥　Después de entrar a la página, paso a paso, escribe el nombre primero, y luego ingrese la calificación general (se requieren 5 estrellas), adjunte fotos/videos, titule su reseña y agregue la reseña.',
        reviewTip1_2: 'Esperamos ver sus comentarios y fotos en la sección de comentarios! ',
        reviewWarning: 'tenga en cuenta:',
        reviewWarning1: 'No incluya una foto de la tarjeta de regalo en su reseña.',
        reviewWarning2: 'Adjunte una reseña del producto anterior, no una foto del producto.',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Adjunte detalles de revisión como en los ejemplos 1 ~ 3 anteriores.',
        imageAlert: 'No envíe solo fotos de productos. Necesitamos una captura de pantalla de la revisión del producto.',
        dialog: {
            title: 'Dejar una nota',
            tip1: 'Consulte {0} para obtener capturas de pantalla, no imágenes del producto.',
            tip2: 'Contenido de la revisión del producto',
            cancel: 'No, enviar de nuevo',
            submit: 'Sí'
        }
    },
    alert: {
        90001: 'Este número de pedido de Amazon ya se ha utilizado, proporcione otro número de pedido de Amazon.',
        90002: 'Elija el mercado correcto.',
        90003: 'Este número de pedido ya se ha enviado. Espere unos días más mientras revisamos su pedido. Una vez que su revisión se refleja en la página del producto de Amazon, Te enviaremos el código de regalo de Amazon en un plazo de 2 a 7 días. Por favor sea paciente.',
        90004: 'Lo sentimos, solo un canje por persona en la misma cuenta. Como ya has canjeado tu Amagif, no será válido canjear por más de uno. Por favor entiende.',
        90005: 'Gracias por su continuo apoyo. \nLo siento, pero su número de pedido no es elegible para la promoción Happy Scratch. (Scratch no elegible: aquellos que ingresan al concurso a través de un tweet / aquellos que requieren soporte postventa). Sin embargo, tenga en cuenta que no podremos atender esta solicitud nuevamente en el futuro.. \nGracias por su comprensión.',
    },
    next: 'Próximo',
    previous: 'Previo',
    submit: 'Entregar',
}