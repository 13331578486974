import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import i18n from '@/plugins/i18n';

Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ?
    "http://127.0.0.1:8080" :
    "http://review.hot-sea.com:8080/review_manager";

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            if (error.response.data) {
                let data = error.response.data
                let key = 'alert.' + data.code
                let msg = i18n.t(key)
                alert(msg && msg !== key ? msg : data.message)
                error.handled = true
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
)

export default axios