import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import router from '@/plugins/router';
import i18n from '@/plugins/i18n';
import axios from '@/plugins/axios';
import store from "@/plugins/store";
import {format} from "@/plugins/util";

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$format = format

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
