module.exports = {
    home: {
        title: 'Congratulations!  Get Your free Amazon Gift!'
    },
    landing: {
        warranty: '24 months warranty'
    },
    redeem: {
        tip1: 'Please note:\n' +
            '①The same order can only be redeemed once\n' +
            '②Each code can only be redeemed once for him / each person',
        tip2: 'Let\'s open this amazing gift.\n' +
            'Please enter your redemption code.',
        code: 'redeem code: ',
        codeAlert: 'Please enter the correct redeem code.',
    },
    warranty: {
        name: 'name:',
        nameAlert: 'Name must not exceed 32 characters.',
        email: 'Email:',
        emailAlert: 'Email address must not exceed 64 characters.',
        emailAlert2: 'Please enter a valid email addressPlease enter a valid email address.',
        order: 'Amazon order number:',
        orderTip: 'Example：249-1234567-1234567（17 characters）',
        orderAlert: 'Please enter your correct Amazon order number.',
        market: 'Purchase from:',
        otherContactTip: 'Note: We will contact you by e-mail, but there is a possibility that the e-mail may be rejected or it may end up in spam. Please include at least one more contact so that we can send your Amanzon gift safely and securely. (LINE/Twitter/others)',
        line: 'LINE ID：',
        lineTip: 'Example：freef',
        facebook: 'Facebook ID：',
        facebookTip: 'Example：freef',
        twitter: 'TWITTER ID：',
        twitterTip: 'Example：@freef',
        otherContact: 'Other ways to contact:',
        otherContactAlert: 'Please provide at least one more contact.',
        warningTip: 'Please note: We strictly protect the personal privacy of consumers and resolutely do not disclose any personal information. And will not contact you in any form to disturb you.'
    },
    finished: {
        title1: 'Thank you very much for taking the time to fill out this form.\n\nOnce your review is reflected on the Amazon product page, we will send you an Amazon gift code. It takes about 2-7 days.\n\nPlease note: Customers who received a gift are not eligible. Please understand that this is not a gift for customers who received a gift.',
        title2: 'Thank you very much for your very detailed answers.\n\nOnce your review is reflected on the Amazon product page, we will send you an Amazon gift code. It takes about 2-7 days.\nIn the unlikely event that you do not receive the e-mail, please check to see if the e-mail address you entered is incorrect or if it goes into your junk mail.\n\nWe look forward to your continued patronage of our company.',
        dissatisfied: 'Thank you very much for taking the time to fill out this form.\n' +
            'We will fully review your proposal internally and will contact you again.\n' +
            '\n' +
            'In the unlikely event that you do not receive the e-mail, please check whether the e-mail address you entered is correct or not.\n' +
            'Please check to see if it goes into your junk mail.\n' +
            '\n' +
            'We look forward to your continued patronage of our company.'
    },
    trial: {
        title: 'For your information, we occasionally hold free new trial campaigns, so please select the free trial product(s) you are interested in from the list below. (Multiple choice)',
        trialAlert: 'Choose at least one',
        submit: 'I am interested in the above products',
        cancel: 'I\'m not interested in all of them.'
    },
    trialFinished: {
        title: 'Thank you very much for your very detailed answers.',
        tip: 'We will contact you after the free trial campaign you are interested in holds.\nWe will guide you through the specific process separately\n' +
            '\n' +
            'In the unlikely event that you do not receive the e-mail, please check whether the e-mail address you entered is correct or not.\nPlease check to see if it goes into your junk mail.\n\n' +
            'We look forward to your continued patronage of our company.'
    },
    survey: {
        title: 'Are you satisfied with your purchase?',
        option1: 'Yes',
        option2: 'No',
        dissatisfiedAlert: 'Please select whether you are satisfied with your purchase.',
        reportTip1: 'Overall, how satisfied are you with this purchase? If you have any questions, comments or requests, please feel free to write to us.',
        reportTip2: 'By constantly asking about customer satisfaction, we can keep abreast of the health of the product, and by freely inputting opinions and requirements, we will find points to improve the product.',
        reportAlert: 'If you have any problems, please do not hesitate to let us know.'
    },
    review: {
        title: 'Thank you for your purchase and support',
        reviewTip1: 'Congratulations, it\'s time to get your gift.' ,
        reviewTip2: 'Please follow the steps below to submit a review on Amazon.',
        reviewTip1_1: '①　Open Amazon and log in\n②　Click on [Your Account]\n③　Click on [Your Orders(See All) in the drop-down box of [Your Account]\n④　Select the product for which you want to write a review\n⑤　Scroll down to find [How’s your item? (Write a product review)]\n⑥　After entering the page, step by step, write the name first, and then enter the overall rating<span class="font-weight-bold red--text">(Requirement must be 5 stars)</span>, attach photos/videos, title your review and add review.',
        reviewTip1_2: 'Look forward to seeing your comments and photos in the comments section! ',
        reviewWarning: 'please note:',
        reviewWarning1: 'Please do not include a photo of the Gift card in your review.',
        reviewWarning2: 'Please attach a review of the above product, not a photo of the product.',
        // reviewTip2: 'ご注意：スクリーンショットは製品の写真ではなく、製品のレビュー内容をご提出ください。',
        // reviewTip3: 'レビュー内容の中にスクラッチカードの写真を添付しないでください。',
        // reviewTip4: '最近、投稿したレビューが削除されてしまうことがあるので、アカウントの安全を保護するため、商品を受け取ったら一週間の程で使用感想をアマゾンでレビューを投稿お願い申し上げます。',
        imageTip: 'Please attach review details as in examples 1~3 above.',
        imageAlert: 'Do not submit only product photos. We need a screenshot of the product review.',
        dialog: {
            title: 'Leave a Note',
            tip1: 'Please check {0} for screenshots, not pictures of the product.',
            tip2: 'Product Review Content',
            cancel: 'No, submit again',
            submit: 'Yes'
        }
    },
    alert: {
        90001: 'This Amazon order number has already been used, please provide another Amazon order number.',
        90002: 'Please choose the correct marketplace.',
        90003: 'This order number has already been submitted. Please wait a few more days while we review your order. Once your review is reflected on the Amazon product page, we will send you the Amazon gift code within 2-7 days. Please be patient.',
        90004: 'Sorry, only one redemption per person on the same account. Since you have already redeemed your Amagif, redeeming for more than one will be invalid. Please understand.',
        90005: 'Thank you for your continued support. \nI apologize, but your order number is not eligible for the Happy Scratch promotion. (Scratch ineligible: those who enter the contest via tweet / those who require after-sales support). However, please note that we will not be able to accommodate this request again in the future. \nThank you for your understanding.',
    },
    next: 'Next',
    previous: 'Previous',
    submit: 'Submit',
}